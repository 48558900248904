import tw from "tailwind.macro";
import React from "react";
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/core';
import SEO from "../components/seo";
import Footer from "../components/Footer";
import ContactSection from "../components/ContactSection";
import Nav from "../components/Nav";

// Change this URL if you need to update the iframe src for the Airtable form
const FORM_SRC = 'https://airtable.com/embed/shr7EdVMu1fZkxQ3X'


const Wrapper = tw.div`
  flex items-center flex-col
`;

const spin = keyframes`
{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`
const SpinnerContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
const SupplyFormContainer = styled('div')`
height: 2975px;
width: 100%;
overflow: auto!important;
-webkit-overflow-scrolling: touch!important;
`
const SupplyForm = styled('iframe')`
height: 100%;
width: 100%;
`

const ContentContainer = tw.div`
  max-w-6xl m-auto pt-20 w-full
`;

class LoadingSpinner extends React.Component {
  render() {
    return (
      <div className="spinner" css={css`
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        transition: opacity 50ms ease;
        `
      }>
        <div css={css`
          box-sizing: border-box;
          display: block;
          position: absolute;
          width: 64px;
          height: 64px;
          margin: 8px;
          border: 8px solid #000;
          border-radius: 50%;
          animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          border-color: #000 transparent transparent transparent;
        `}></div>
        <div css={css`animation-delay: -0.45s;`}></div>
        <div css={css`animation-delay: -0.3s;`}></div>
        <div css={css`animation-delay: -0.15s;`}></div>
      </div>
    )
  }
}


export default () => (
  <Wrapper>
    <SEO title="Home" />
    <Nav />
    <ContentContainer>
      <SpinnerContainer><LoadingSpinner></LoadingSpinner></SpinnerContainer>
      <SupplyFormContainer>
        <SupplyForm onLoad={() => { document.querySelector('.spinner').style.display = 'none' }} src={FORM_SRC} frameborder="0"></SupplyForm>
      </SupplyFormContainer>
    </ContentContainer>
    <ContactSection />
    <Footer />
  </Wrapper>
)